import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card,
  Form,
  Toast,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  addUser,
  updateUser,
  getUser,
  deleteuser,
} from "../Services/User.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
// import { findAllInRenderedTree } from "react-dom/test-utils";
import { toast } from "react-toastify";
import { imageURL } from "../config/config";
// import { Action } from "../config/action";

export default function Users() {
  const params = useParams();

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);

  const [payload, setpayload] = useState({
    userDescribe: "",
    userEmail: "",
    userFullName: "",
    userLastOnline: "",
    userLoginVerified: "",
    userName: "",
    userVerified: "",
    usershowEmail: "",
  });

  const [file, setfile] = useState({});

  const reset = () => {
    setid("");
    setpayload({
      userDescribe: "",
      userEmail: "",
      userFullName: "",
      userLastOnline: "",
      userLoginVerified: "",
      userName: "",
      userVerified: "",
      usershowEmail: "",
    });
    setfile({});
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [Userdata, setUserdata] = useState([]);
  const [Usercount, setUsercount] = useState(0);
  const [status, setstatus] = useState("");
  useEffect(() => {
    if (status) {
      setfilterdata(
        Userdata.filter((item) => {
          return item.userLoginVerified === status;
        })
      );
    }
  }, [status]);
  const getdata = async () => {
    const data = await getUser();

    if (data.success == true) {
      const N =
        data.data.length % 10 == 0
          ? parseInt(data?.data?.length / 10)
          : parseInt(data?.data.length / 10) + 1;

      setcount(Array.from({ length: N }, (_, index) => index + 1));
      setUsercount(data.data.length);
      setUserdata(data.data);
    } else {
      toast.error(data.message);
    }
  };

  // const getdatabyquery = async (query) => {
  //   const data = await getuserbyquery(query);

  //   if (data.status == 1) {
  //     const N =
  //       data?.count % 10 == 0
  //         ? parseInt(data?.count / 10)
  //         : parseInt(data?.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.count);
  //     setuser(data.data);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const updatedata = async () => {
    const response = await updateUser(id, payload);

    if (response.success == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    getdata();
    reset();
  };
  const deletedata = async () => {
    const response = await deleteuser(id);
    if (response.success == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }

    getdata();
    reset();
  };
  const insertdata = async () => {
    const data = await addUser(payload);
    if (data.status == true) {
      toast.success(data.message);
      getdata();
    } else {
      toast.error(data.message);
    }

    // reset();
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });

  useEffect(() => {
    const values = Object.values(filter);

    if (values[1] != "") {
      setfilterdata(
        Userdata.filter((item) => {
          return item[filter.attribute] == filter.value;
        })
      );
    } else {
      setfilterdata([]);
    }
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        Userdata?.length % 10 == 0
          ? parseInt(Userdata?.length / 10)
          : parseInt(Userdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [filterdata]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }

    getdata();
  }, [page]);
  console.log(status);
  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          {/* <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>users</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add
            </Button>
          </div> */}
          <h1>Users</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      User Status
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setstatus(false);
                        }}
                      >
                        Unverified
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setstatus(true);
                        }}
                      >
                        Verified
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col style={{ display: "flex" }}>
                  <input
                    type="text"
                    placeholder="Search By User Name"
                    onChange={(e) => {
                      setfilterdata(
                        Userdata.filter((item) => {
                          return item.userName == e.target.value;
                        })
                      );
                    }}
                  ></input>
                </Col>
                <Col style={{ display: "flex" }}>
                  <input
                    type="text"
                    placeholder="Search By User Email"
                    onChange={(e) => {
                      setfilterdata(
                        Userdata.filter((item) => {
                          return item.userEmail == e.target.value;
                        })
                      );
                    }}
                  ></input>
                </Col>

                <Col>
                  <Button
                    onClick={() => {
                      setshowncreatemodal(true);
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>

              <Table class="" responsive>
                <thead className="thead">
                  <tr>
                    <td>Profile</td>
                    <td>User Name</td>
                    <td>Description</td>
                    <td>Email</td>
                    <td>Last login </td>
                    <td>Status</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filterdata.length > 0
                    ? filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                        .map((item, index) => {
                          const date = Date(item?.userLastOnline);
                          return (
                            <tr>
                              <td>
                                <img
                                  className="profileimg"
                                  src={item.userImage}
                                ></img>
                              </td>
                              <td>
                                {item.userName
                                  // ? item.userName
                                  // : item.userFullName
                                  }
                              </td>
                              <td>{item?.userDescribe}</td>
                              <td>{item?.userEmail}</td>

                              <td>{date.split("GMT")[0]}</td>
                              <td>
                                {item.userLoginVerified
                                  ? "Verified"
                                  : "Unverify"}
                              </td>

                              <td>
                                <div className="action">
                                  <i
                                    class="icon-edit"
                                    onClick={() => {
                                      setid(item.id);
                                      setpayload(item);
                                      setfile(item.userImage);
                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  <i
                                    class="icon-trash"
                                    onClick={() => {
                                      setid(item.id);

                                      setshowndeletemodal(true);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : Userdata?.slice(
                        (page - 1) * 10,
                        (page - 1) * 10 + 10
                      ).map((item, index) => {
                        const date = Date(item?.userLastOnline);
                        return (
                          <tr>
                            <td>
                              <img
                                className="profileimg"
                                src={imageURL + item.userImage}
                              ></img>
                            </td>
                            <td>
                              {item.userName
                                // ? item.userName
                                // : item.userFullName
                                }
                            </td>
                            <td>{item?.userDescribe}</td>
                            <td>{item?.userEmail}</td>

                            <td>{date.split("GMT")[0]}</td>
                            <td>
                              {item.userVerified ? "Verified" : "Unverify"}
                            </td>

                            <td>
                              <div className="action">
                                <i
                                  class="icon-edit"
                                  onClick={() => {
                                    setid(item.id);
                                    setpayload(item);
                                    setfile(item.userImage);

                                    setshownupdatemodal(true);
                                  }}
                                ></i>

                                <i
                                  class="icon-trash"
                                  onClick={() => {
                                    setid(item.id);

                                    setshowndeletemodal(true);
                                  }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing{" "}
                {filterdata.length > 0
                  ? filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      .length
                  : Userdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      .length}

                - {filterdata.length > 0?filterdata.length :Usercount} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next1
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next2
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* delete modal */}
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* {update modal} */}

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <label>User Name</label>{" "}
                <input
                  name="name"
                  type="text"
                  placeholder="Enter User Name"
                  value={payload.userName}
                  onChange={(e) =>
                    setpayload({ ...payload, userName: e.target.value })
                  }
                />
              </Row>
              <Row>
                {" "}
                <label>User Email</label>{" "}
                <input
                  name="name"
                  type="text"
                  placeholder="Enter Email"
                  value={payload.userEmail}
                  onChange={(e) =>
                    setpayload({ ...payload, userEmail: e.target.value })
                  }
                />
              </Row>
              <Row>
                <label>User Describe</label>{" "}
                <input
                  name="name"
                  type="text"
                  placeholder="Enter User Describe"
                  value={payload.userDescribe}
                  onChange={(e) =>
                    setpayload({ ...payload, userDescribe: e.target.value })
                  }
                />
              </Row>
              {/* <Row>
                <label>User Profile</label>
                {file[0] ? (
                  <img src={file} />
                ) : (
                  <input type="file" onChange={(e) => {
                  console.log(file)
                    setfile(e.target.files[0])
                  }} />
                )}
              </Row> */}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setid("");
                reset();

                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  insertdata();
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  updatedata();
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
