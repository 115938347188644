import { Action } from "../config/action";

export const getSubscription = async () => {
  const data = await Action.get(`/getsubscription`);
  
    return data.data.data;
  };

  export const deletesubscription = async (id)=>{
    const data = await Action.delete(`/deletesubscription/${id}`)
    return data.data
  }
  export const updatesubscription = async (id,payload)=>{
    const data = await Action.put(`/updatesubscription/${id}`,payload)
    return data.data
  }
  export const insertSubscriptions =async (payload)=>{
    const data = await Action.post(`/createsubscription`,payload)
    return data.data
  }
  