import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { adminLogout } from "../Services/Video.service";

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();

  const path = window.location.pathname.split("/")[1].toLowerCase();

  // if (window.innerWidth > 500) {
  return (
    <div className="sidebar">
      <ul className="menu-pst">
        {path == "dashboard" ? (
          <li className="menu-item active">
            <i class="icon-home"></i>
            <span>Dashboard</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/dashboard")}>
            <i class="icon-home"></i>
            <span>Dashboard</span>
          </li>
        )}
        {path == "users" ? (
          <li className="menu-item active">
            <i class="icon-shopping-cart"></i>
            <span>Users</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/users")}>
            <i class="icon-shopping-cart"></i>
            <span>Users</span>
          </li>
        )}
        {path == "review" ? (
          <li className="menu-item active">
            <i class="icon-shopping-cart"></i>
            <span>Reviews</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/review")}>
            <i class="icon-shopping-cart"></i>
            <span>Reviews</span>
          </li>
        )}

        {path == "userrequest" ? (
          <li className="menu-item active">
            <i class="icon-box"></i>
            <span>User Request</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/userrequest")}>
            <i class="icon-box"></i>
            <span>User Request</span>
          </li>
        )}

        {path == "subscription" ? (
          <li className="menu-item active">
            <i class="icon-box"></i>
            <span>Subscription</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/subscription")}>
            <i class="icon-box"></i>
            <span>Subscription</span>
          </li>
        )}
        {path == "notification" ? (
          <li className="menu-item active">
            <i class="icon-users"></i>
            <span>Notification</span>
          </li>
        ) : (
          <li className="menu-item" onClick={() => navigate("/notification")}>
            <i class="icon-users"></i>
            <span>Notification</span>
          </li>
        )}

        <li
          className="menu-item"
          onClick={async () => {
            try {
              localStorage.setItem("isLogin", JSON.stringify(false));
              localStorage.setItem("user", "");
              localStorage.setItem("token", "");
              navigate("/");

              //  const response = await adminLogout();
              //  console.log(response.status)

              //  if (response.status == 1) {

              //    navigate("/");
              //  } else {
              //    navigate("/");
              //    localStorage.setItem("isLogin", JSON.stringify(false));

              //    toast.error(response.message);
              //  }
            } catch (e) {
              toast.error(e.message);
              localStorage.setItem("isLogin", JSON.stringify(false));

              navigate("/");
            }
          }}
        >
          <i class="icon-log-out"></i>
          <span>Logout</span>
        </li>
      </ul>
    </div>
  );

  // }else{
  //   return(
  //     <>
  //       <Button
  //         variant=""
  //         className="toggle_btn"
  //         onClick={handleShow}
  //         style={{ background: "white", border: "none" }}
  //       >
  //         <img src={toggle} className="toggle_icon" />
  //       </Button>
  //       <Offcanvas show={show} onHide={handleClose}>

  //         <Offcanvas.Header closeButton>
  //           <Offcanvas.Title>
  //             <img
  //               className="logo"
  //               onClick={() => navigate("/")}
  //               style={{ cursor: "pointer" }}
  //               src={logo}
  //             />
  //           </Offcanvas.Title>
  //         </Offcanvas.Header>
  //         <Offcanvas.Body>
  //         <div className="sidebar">
  //       {/* <div className="logo">
  //         <img src={logo} />
  //       </div> */}
  //       {/* <ul className="menu-pst"> */}
  //         <p>
  //           {path == "dashboard" ? (
  //             <div className="menu-item active">
  //               <img src={dashboarda} />
  //                <span style={{"marginLeft":"30px"}}>Dashboard</span>
  //             </div>
  //           ) : (
  //             <div className="menu-item" onClick={() => navigate("/dashboard")}>
  //               <img src={dashboard} />
  //                <span style={{"marginLeft":"30px"}}>Dashboard</span>
  //             </div>
  //           )}
  //         </p>
  //         <p>
  //           {path == "functionalarea" ? (
  //             <div className="menu-item active">
  //               <img src={functionAreaa} />
  //                <span style={{"marginLeft":"30px"}}>Functional Area</span>
  //             </div>
  //           ) : (
  //             <div
  //               className="menu-item"
  //               onClick={() => navigate("/functionalarea")}
  //             >
  //               <img src={functionArea} />
  //                <span style={{"marginLeft":"30px"}}>Functional Area</span>
  //             </div>
  //           )}
  //         </p>
  //         <p>
  //           {path == "venues" ? (
  //             <div className="menu-item active">
  //               <img src={venuesa} />
  //                <span style={{"marginLeft":"30px"}}>Venues</span>
  //             </div>
  //           ) : (
  //             <div className="menu-item" onClick={() => navigate("/venues")}>
  //               <img src={venues} />
  //                <span style={{"marginLeft":"30px"}}>Venues</span>
  //             </div>
  //           )}
  //         </p>
  //         <p>
  //           {path == "department" ? (
  //             <div className="menu-item active">
  //               <img src={departmenta} />
  //                <span style={{"marginLeft":"30px"}}>Departments</span>
  //             </div>
  //           ) : (
  //             <div className="menu-item" onClick={() => navigate("/department")}>
  //               <img src={department} />
  //                <span style={{"marginLeft":"30px"}}>Departments</span>
  //             </div>
  //           )}
  //         </p>
  //         <p>
  //           {path == "zones" ? (
  //             <div className="menu-item active">
  //               <img src={zonea} />
  //                <span style={{"marginLeft":"30px"}}>Zones</span>
  //             </div>
  //           ) : (
  //             <div className="menu-item" onClick={() => navigate("/zones")}>
  //               <img src={zone} />
  //                <span style={{"marginLeft":"30px"}}>Zones</span>
  //             </div>
  //           )}
  //         </p>
  //         <p>
  //           {path == "history" ? (
  //             <div className="menu-item active">
  //               <img src={historya} />
  //                <span style={{"marginLeft":"30px"}}>History</span>
  //             </div>
  //           ) : (
  //             <div className="menu-item" onClick={() => navigate("/history")}>
  //               <img src={history} />
  //               <span style={{"marginLeft":"30px"}}>History</span>
  //             </div>
  //           )}
  //         </p>
  //       {/* </ul> */}
  //     </div>
  //           </Offcanvas.Body>
  //           </Offcanvas>
  //     </>
  //   )
  // }
}
