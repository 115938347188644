import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../Services/Review.service";
import { toast } from "react-toastify";

export default function Login() {
  const [email, setemail] = useState("jasonowsum@gmail.com");
  const [password, setpassword] = useState("12341234");
  const navigate = useNavigate();
  const login = async () => {
    try{
      const response = await adminLogin({userEmail:email, userPassword:password });
      
      if (response.success == true) {
        console.log(response)
        localStorage.setItem("isLogin", JSON.stringify(true));
        localStorage.setItem("user", JSON.stringify(response.data));
        // localStorage.setItem("token", JSON.stringify(response.data.token));

  
        navigate("/dashboard");
      }else{
        toast.error(response.message);
      }
    }catch(e){
      console.log(e)
      toast.error(e.message);
    }
  };

  const islogin = JSON.parse(localStorage.getItem("isLogin"));

  useEffect(() => {
    if (islogin == true) {
      navigate("/dashboard");
    }
  }, [JSON.parse(localStorage.getItem("isLogin"))]);

  return (
    <Container>
      <Row className="">
        <Col xl="6" lg="8" className="mx-auto">
          <div className="loginform">
            {/* <img src={logo} alt="" /> */}

            <div class="bg-white p-4 w-100">
              <div class="text-center content-area">
                <div className="logo">
                  <img src={Logo} alt="Logo" />
                </div>
                <h2 class="main-heading mb-4">Login</h2>

                <div class="form-data">
                  <input
                    type="text"
                    class=""
                    placeholder="admin@mail.com"
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                </div>
                <div class="form-data">
                  <input
                    type="text"
                    class=""
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                  />
                </div>
                {/* <div class="d-flex justify-content-between w-100 align-items-center checkbox-div">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                </div>
                <a class="text-decoration-none forgot-text" href="#">
                  Forgot password?
                </a>
              </div> */}
                <div class="signin-btn w-100 mt-2">
                  <button
                    class="btn btn-primary btn-block"
                    onClick={() => {
                      login();
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
