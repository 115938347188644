import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Dropdown,
  Modal,
  Table,
  Card  
} from "react-bootstrap";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  getreview, addreview, updatereview,deletereview
} from "../Services/Review.service";
import {  useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

export default function Review() {

  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);

  const [payload, setpayload] = useState({    
    userreview:'',   
    
  });
  const [image,setimage] = useState({})
  const [thumbnail,setthumbnail] = useState({})

  const reset = () => {
    setid("");
    setpayload({
    userreview:'',   
      
    });
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [review, setreview] = useState([]);
  const [reviewcount, setreviewcount] = useState(0);
  const getdata = async () => {
    const data = await getreview();
    console.log(data)

    if (data.success == true) {
      
      const N =
        data?.data.length % 10 == 0
          ? parseInt(data?.data.length / 10)
          : parseInt(data?.data.length / 10) + 1;

      setcount(Array.from({ length: N }, (_, index) => index + 1));
      setreviewcount(data.data.length);
      setreview(data.data);
    } else {
      toast.error(data.message);
    }
    console.log(review)

  };

  // const getdatabyquery = async (query) => {
  //   const data = await getuserbyquery(query);

  //   if (data.status == 1) {
  //     const N =
  //       data?.count % 10 == 0
  //         ? parseInt(data?.count / 10)
  //         : parseInt(data?.count / 10) + 1;

  //     setcount(Array.from({ length: N }, (_, index) => index + 1));
  //     setusercount(data.count);
  //     setuser(data.data);
  //   } else {
  //     toast.error(data.message);
  //   }
  // };

  const updatedata = async () => {
    const response = await updatereview(id, payload);

    if (response.success == true) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
    getdata();
    reset();
  };
  // const deletedata = async () => {
  //   const response = await deleteUser(id);
  //   if (response.status == 1) {
  //     toast.success(response.message);
  //   } else {
  //     toast.error(response.message);
  //   }

  //   getdata();
  //   reset();
  // };
  const insertdata = async () => {
    const data = await addreview(payload);
    if (data.success == true) {
      toast.success(data.message);
      getdata(page);
    } else {
      toast.error(data.message);
    }

    // reset();
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });

  useEffect(() => {
    const values = Object.values(filter);
    console.log(filter)
    if (values[1] != "") {
      setfilterdata(
        review.filter((item) => {
          if(filter.attribute=="userName"){

          return item.userid[filter.attribute] == filter.value;
          }else{

            return item[filter.attribute] == filter.value;
          }
        })
      );
    } else {
      setfilterdata([]);
    }
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        review?.length % 10 == 0
          ? parseInt(review?.length / 10)
          : parseInt(review?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [filterdata]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }

    getdata();
  }, [page]);
  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          {/* <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>users</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add
            </Button>
          </div> */}
          <h1>Reviews</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Search by
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "userrating" });
                        }}
                      >
                        User Rating
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ ...filter, attribute: "userName" });
                        }}
                      >
                        User Name
                      </Dropdown.Item>{" "}
                      
                    </Dropdown.Menu>
                  </Dropdown>
                  {filter.attribute == "userrating" ? (
                  <input
                  className="ml-3"
                  type="Number"
                  placeholder={filter.attribute}
                  onChange={(e) => {
                    setfilter({ ...filter, value: e.target.value });
                  }}
                ></input>
                  ) : (filter.attribute == "userName"?(

                    <input
                      className="ml-3"
                      type="text"
                      placeholder={filter.attribute}
                      onChange={(e) => {
                        setfilter({ ...filter, value: e.target.value });
                      }}
                    ></input>
                  ):null 
                  )}
                </Col>
                <Col></Col>

                <Col></Col>
              </Row>

              <Table class="" responsive>
                <thead className="thead">
                  <tr>
                    <td>S.No</td>
                    <td>User Name</td>
                    {/* <td>City Name</td> */}
                    <td>User Review </td>
                    <td>Rating</td>
                    

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    filterdata.length > 0
                    ? filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                  ?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index+1}</td>
                        <td>
                          {item?.userid?.userName
                            ? item?.userid?.userName
                            : item?.userid?.userFullName }
                        </td>
                        <td>
                          <p>{item?.userreview}</p>
                        </td>
                        <td>
                          <p>{item?.userrating}</p>
                        </td>
                        

                        <td>
                          <div className="action">
                            <i
                              class="icon-edit"
                              onClick={() => {
                                setid(item._id);
                                setpayload(item);
                                setshownupdatemodal(true);
                              }}
                            ></i>

                            <i
                              class="icon-trash"
                              onClick={() => {
                                setid(item._id);

                                setshowndeletemodal(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  }):review?.slice(
                    (page - 1) * 10,
                    (page - 1) * 10 + 10
                  )?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index+1}</td>
                        <td>
                          {item?.userid?.userName
                            ? item?.userid?.userName
                            : item?.userid?.userFullName }
                        </td>
                        <td>
                          <p>{item?.userreview}</p>
                        </td>
                        <td>
                          <p>{item?.userrating}</p>
                        </td>
                        

                        <td>
                          <div className="action">
                            <i
                              class="icon-edit"
                              onClick={() => {
                                setid(item._id);
                                setpayload(item);
                                setshownupdatemodal(true);
                              }}
                            ></i>

                            <i
                              class="icon-trash"
                              onClick={() => {
                                setid(item._id);

                                setshowndeletemodal(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                
                }
                </tbody>
              </Table>
              <div className="menupagination">
                Showing{" "}
                {filterdata.length > 0
                  ? filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      .length
                  : review?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      .length}
                - {reviewcount} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next1
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next2
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {/* delete modal
        <Modal
          show={showndeletemodal}
          onHide={() => {
            reset();

            setshowndeletemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="secondary"
                type="submit"
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");

                  setshowndeletemodal(false);
                }}
              >
                No
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  deletedata();
                  setshowndeletemodal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal> */}

        <Modal
          show={showncreatemodal || shownupdatemodal}
          size="lg"
          onHide={() => {
            reset();

            setshowncreatemodal(false);
            setshownupdatemodal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {showncreatemodal ? "Add" : "Update"} Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                
                <Col className="mb-3">
                  {" "}
                  <label>User Review</label>
                  {" "}
                  <input
                    name="name"
                    type="text"
                    placeholder="First Name"
                    value={payload.userreview}
                    onChange={(e) =>
                      setpayload({ ...payload, userreview: e.target.value })
                    }
                  />
                </Col>
             
               
              </Row>

              
             
            
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                
                reset();
                setshowncreatemodal(false);
                setshownupdatemodal(false);
              }}
            >
              Cancel
            </Button>
            {showncreatemodal ? (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  insertdata();
                  // createfa(e);
                }}
              >
                Create
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                  updatedata();
                  // updatefa(id, payload);
                }}
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
