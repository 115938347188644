import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  Dropdown,
  Card,
  Table,
  Form,
} from "react-bootstrap";
import edit from "../assets/images/Edit.png";
import deleteicon from "../assets/images/delete.png";
import Sidebar from "../component/sidebar";
import Header from "../component/header";

import {
  deletesubscription,
  getSubscription,
  insertSubscriptions,
  updatesubscription,
} from "../Services/Subscription.service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Subscription() {
  const [id, setid] = useState("");
  const [showndeletemodal, setshowndeletemodal] = useState(false);
  const [showncreatemodal, setshowncreatemodal] = useState(false);
  const [shownupdatemodal, setshownupdatemodal] = useState(false);
  const [payload, setpayload] = useState({
    title: "",
    posting: false,
    unlimited_notes: false,
    scribble_package: false,
    price: 0,
  });
  const reset = () => {
    setid("");
    setpayload({});
  };

  const [count, setcount] = useState([]);
  const [page, setpage] = useState(1);

  const [subscription, setsubscription] = useState([]);

  const getdata = async () => {
    setsubscription(await getSubscription());
  };

  const [filterdata, setfilterdata] = useState([]);

  const [filter, setfilter] = useState({
    attribute: "Select any",
    value: "",
  });
  useEffect(() => {
    // if (filter.attribute == "subId") {
    //   setfilterdata(
    //     subscription.filter((item) => {
    //       return item.subId.slice(-5) == filter.value;
    //     })
    //   );
    // } else {
    // }
    setfilterdata(
      subscription.filter((item) => {
        return item[filter.attribute] == filter.value;
      })
    );
  }, [filter.value]);

  useEffect(() => {
    if (filterdata.length == 0) {
      const N =
        subscription?.length % 10 == 0
          ? parseInt(subscription?.length / 10)
          : parseInt(subscription?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    } else {
      const N =
        filterdata?.length % 10 == 0
          ? parseInt(filterdata?.length / 10)
          : parseInt(filterdata?.length / 10) + 1;
      setcount(Array.from({ length: N }, (_, index) => index + 1));
    }
  }, [subscription]);

  const islogin = JSON.parse(localStorage.getItem("isLogin"));
  const navigate = useNavigate();
  useEffect(() => {
    if (islogin != true) {
      navigate("/");
    }
    setTimeout(() => {
      getdata();
    }, 1000);
    getdata();
  }, []);

  const updatedata = async () => {
    console.log(payload)
    const value = await updatesubscription(id, payload);

    if (value.success == true) {
      toast.success(value.message);
    } else {
      toast.success(value.message);
    }

    setfilter({ ...filter, value: "" });

    getdata();
    reset();
  };
  const deletedata = async () => {
    const data = await deletesubscription(id);
    getdata();
    setfilter({ ...filter, value: "" });
    reset();
  };
  const insertdata = async () => {
    const value = await insertSubscriptions(payload);
    if (value.success == true) {
      toast.success(value.message);
    } else {
      toast.success(value.message);
    }
    getdata();
    reset();
  };

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1>Subscription</h1>
            <Button
              onClick={() => {
                setshowncreatemodal(true);
              }}
            >
              Add
            </Button>
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {filter?.attribute}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "title" });
                        }}
                      >
                        Title
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setfilter({ attribute: "weekly_cost" });
                        }}
                      >
                        Cost
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                  <input
                    style={{ marginLeft: "20px" }}
                    type="text"
                    placeholder={filter.attribute}
                    onChange={(e) => {
                      setfilter({ ...filter, value: e.target.value });
                    }}
                  ></input> */}
                </Col>
                <Col></Col>

                <Col></Col>
              </Row>

              <Table responsive>
                <thead className="thead">
                  <tr>
                    <td>Title</td>
                    <td>Posting</td>
                    <td>Unlimited Note</td>
                    <td>Scribble Package</td>
                    <td>Price</td>
                    <td>Subscribers</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filterdata.length == 0
                    ? subscription
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                        ?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.title}</td>

                              <td>
                                {item?.posting ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>

                              <td>
                                {item?.unlimited_notes ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>
                              <td>
                                {item?.scribble_package ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>

                              <td>{item?.price}</td>
                              <td>{item?.subscribedUsers?.length}</td>

                              <td>
                                <div className="action">
                                  <i
                                    class="icon-edit"
                                    onClick={() => {
                                      setid(item._id);
                                      setpayload(item);

                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  <i
                                    class="icon-trash"
                                    onClick={() => {
                                      setid(item._id);

                                      setshowndeletemodal(true);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)

                        ?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.title}</td>

                              <td>
                                {item?.posting ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>

                              <td>
                                {item?.unlimited_notes ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>
                              <td>
                                {item?.scribble_package ? (
                                  <span>&#x2705;</span>
                                ) : (
                                  <span>&#x274C;</span>
                                )}
                              </td>

                              <td>{item?.price}</td>
                              <td>{item?.subscribedUsers?.length}</td>

                              <td>
                                <div className="action">
                                  <i
                                    class="icon-edit"
                                    onClick={() => {
                                      setid(item._id);
                                      setpayload(item);

                                      setshownupdatemodal(true);
                                    }}
                                  ></i>

                                  <i
                                    class="icon-trash"
                                    onClick={() => {
                                      setid(item._id);

                                      setshowndeletemodal(true);
                                    }}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                </tbody>
              </Table>

              {filterdata.length == 0 ? (
                <div className="menupagination">
                  Showing{" "}
                  {
                    subscription?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {subscription?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="menupagination">
                  Showing{" "}
                  {
                    filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      ?.length
                  }
                  - {filterdata?.length} results
                  <div className="pagination">
                    {page == 1 ? (
                      <button disable style={{ cursor: "default" }}>
                        Prev
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page - 1);
                        }}
                      >
                        Prev
                      </button>
                    )}

                    {count?.map((item, index) => {
                      return (
                        <button className={item == page ? "active" : ""}>
                          {item}
                        </button>
                      );
                    })}
                    {page == count?.length ? (
                      <button disable style={{ cursor: "default" }}>
                        Next
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setpage(page + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>

          <Modal
            show={showndeletemodal}
            onHide={() => {
              reset();

              setshowndeletemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="danger"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setid("");

                    setshowndeletemodal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    deletedata();
                    setshowndeletemodal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showncreatemodal || shownupdatemodal}
            onHide={() => {
              reset();

              setshowncreatemodal(false);
              setshownupdatemodal(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {showncreatemodal ? "Add" : "Update"} Subscription
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row className="inputrow">
                  <Col className="mb-3">
                    <label>Title</label>
                    <input
                      name="name"
                      type="text"
                      placeholder="Title"
                      value={payload.title}
                      onChange={(e) =>
                        setpayload({ ...payload, title: e.target.value })
                      }
                    />
                  </Col>

                  <Col className="mb-3">
                    <label>Cost </label>
                    <input
                      name="name"
                      type="Number"
                      placeholder="Cost"
                      value={payload.price}
                      onChange={(e) =>
                        setpayload({ ...payload, price: e.target.value })
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <label>Posting</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.posting}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            posting: !payload.posting,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                  {/* <Col>Picture Upload</Col> */}
                  <Col className="mb-3">
                    <label>Unlimited Note</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.unlimited_notes}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            unlimited_notes: !payload.unlimited_notes,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  <Col className="mb-3">
                    <label>Scribble Package</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.scribble_package}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            scribble_package: !payload.scribble_package,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>

                  {/* <Col className="mb-3">
                  <label>Position Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.position_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            position_filter: !payload.position_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col> */}
                </Row>
                {/* <Row className="inputrow">
                  <Col className="mb-3">
                  <label>Mile Radius Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.mile_radius_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            mile_radius_filter: !payload.mile_radius_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
              
                  <Col className="mb-3">
                  <label>Pay Model Filter</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.pay_model_filter}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            pay_model_filter: !payload.pay_model_filter,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
               
                  <Col className="mb-3">
                  <label>Question Answer</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.question_answer}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            question_answer: !payload.question_answer,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                  <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.social}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            social: !payload.social,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
              
                  <Col className="mb-3">
                  <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.swipping}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            swipping: !payload.swipping,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                
                  <Col className="mb-3">
                  <label>Social</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.ads}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            ads: !payload.ads,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col className="mb-3">
                  <label>Invisible</label>
                    <div className="custom-switch">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={payload.invisible}
                        onChange={(e) => {
                          setpayload({
                            ...payload,
                            invisible: !payload.invisible,
                          });
                        }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="inputrow">
                  <Col>
                  <label>Spotlight per week</label>
                    {" "}
                    <input
                      name="name"
                      type="Number"
                      placeholder="spotlight per week"
                      value={payload.spotlightperweek}
                      onChange={(e) =>
                        setpayload({
                          ...payload,
                          spotlightperweek: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row className="inputrow">
                  <Col>
                  <label>Backtract</label>
                  <div className="d-flex align-items-center">
                    <input
                      name="name"
                      type="checkbox"
                      label="Is_limit"
                      className="mr-2 check"
                      checked={payload.backtrack_islimit}
                      onChange={(e) =>
                        setpayload({
                          ...payload,
                          backtrack_islimit: !payload.backtrack_islimit,
                        })
                      }
                       />
                    {payload.backtrack_islimit ? (
                      <input
                        name="name"
                        type="Number"
                        placeholder="backtrack limit"
                        value={payload.backtrack_limit}
                        onChange={(e) =>
                          setpayload({
                            ...payload,
                            backtrack_limit: e.target.value,
                          })
                        }
                      />
                    ) : null}
                  </div>
                  </Col>
             
                  <Col>
                    <label>Application Limit</label>
                    <div className="d-flex align-items-center">
                    <input
                      name="name"
                      type="checkbox"
                      label="Is_limit"
                      className="mr-2 check"
                      checked={payload.application_islimit}
                      onChange={(e) =>
                        setpayload({
                          ...payload,
                          application_islimit: !payload.application_islimit,
                        })
                      }
                    />
                    {payload.application_islimit ? (
                      <input
                        name="name"
                        type="Number"
                        placeholder="applicaion limit"
                        value={payload.application_limit}
                        onChange={(e) => {
                          
                          setpayload({
                            ...payload,
                            application_limit: e.target.value,
                          }
                          );
                        }}
                      />
                    ) : null}
                    </div>
                  </Col>
                </Row> */}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setid("");
                  reset();

                  setshowncreatemodal(false);
                  setshownupdatemodal(false);
                }}
              >
                Cancel
              </Button>
              {showncreatemodal ? (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    setshowncreatemodal(false);
                    setshownupdatemodal(false);
                    insertdata();
                    // createfa(e);
                  }}
                >
                  Create
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    setshowncreatemodal(false);
                    setshownupdatemodal(false);
                    updatedata();
                    // updatefa(id, payload);
                  }}
                >
                  Update
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </>
  );
}
