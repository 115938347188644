import "./App.css";
import "./assets/css/fonts/style.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Users from "./pages/Users";
import Notification from "./pages/Notification";
import Subscription from "./pages/Subscription";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Error from "./pages/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Review from "./pages/Review";
import UserRequest from "./pages/UserRequest";
function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/*" element={<Error />}></Route>
          <Route path="/dashboard" element={<Dashboard />}>
            {" "}
          </Route>
          {/* <Route path="/dashboard" element={<Notification/>}> </Route> */}

          <Route path="/users" element={<Users />}></Route>
          <Route path="/review" element={<Review />}></Route>
          <Route path="/notification" element={<Notification />}></Route>
          <Route path="/subscription" element={<Subscription />}></Route>
<Route path="/userrequest" element={<UserRequest/>}></Route>
          {/* <Route path="/users/:id" element={<Users />}></Route>           */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
