import { Action } from "../config/action";

export const getUser = async () => {
  const data = await Action.get("/getuser");

  return data.data;
};
export const deleteuser = async (id) => {
  const data = await Action.delete(`/deleteuserbyid/${id}`);

  return data.data;
};
export const addUser = async (payload) => {
  const createddata = await Action.post("/User/create", payload);
  return createddata.data;
};
export const updateUser = async (id, payload) => {
  console.log(id, payload);
  const data = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => (v != null) & (v != ""))
  );

  const updateddata = await Action.put(`/updateuserbyid/${id}`, data);
  return updateddata.data;
};
export const getUserRequest = async () => {
  const data = await Action.get("/getrequests");

  return data.data;
};
export const updateuserrequest = async (id, payload) => {
  console.log(id, payload)
  
  const data = await Action.post(`/updaterequest/${id}`, payload);

  return data.data;
};
