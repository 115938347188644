import React from 'react'
import {  useNavigate } from 'react-router-dom'

export default function Error() {
    const navigate = useNavigate()
  return (
      <div 
      style={{fontSize:'24px'}}
    >
        Page Not found
        Click <span
         style={{color:'blue', cursor:"pointer"}}
         onClick={(e)=>{
            navigate("/")
        }}>here</span> for redirect login page
        </div>
  )
}
