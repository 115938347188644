

// export const imageURL="http://localhost:3060/"

export const imageURL="https://api1.jumppace.com:3060/"



export const baseURL=`${imageURL}api/v1`

