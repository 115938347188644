import React, { useEffect, useState } from "react";
import Sidebar from "../component/sidebar";
import Header from "../component/header";
import { toast } from "react-toastify";
import { getUserRequest, updateuserrequest } from "../Services/User.service";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";

export default function UserRequest() {
  const [status, setstatus] = useState("");
  const [page, setpage] = useState(1);

  const [data, setdata] = useState([]);
  const [datacount, setdatacount] = useState(0);
  const [count, setcount] = useState([]);
  const [filterdata, setfilterdata] = useState([]);

  const getdata = async () => {
    const data = await getUserRequest();

    if (data.success == true) {
      const N =
        data.data.length % 10 == 0
          ? parseInt(data?.data?.length / 10)
          : parseInt(data?.data.length / 10) + 1;

      setcount(Array.from({ length: N }, (_, index) => index + 1));
      setdatacount(data.data.length);
      setdata(data.data);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const updateStatus = async (id,newstatus) => {
    
    const response = await updateuserrequest(id, {status:newstatus});
    if (response.success == true) {
      getdata();
    } else {
      toast.error(response.message);
    }
  };
  useEffect(() => {
    setfilterdata(
      data.filter((item) => {
        return item.status == status;
      })
    );
  }, [status]);

  return (
    <>
      <Sidebar />
      <Header />
      <div className="wrapper">
        <Container fluid>
          <h1>Users Request</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col style={{ display: "flex" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      Select Status
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => {
                          setstatus("pending");
                        }}
                      >
                        Pending
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          setstatus("approved");
                        }}
                      >
                        Accepted
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={(e) => {
                          setstatus("rejected");
                        }}
                      >
                        Rejected
                      </Dropdown.Item>{" "}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col>
                  <input
                    type="text"
                    placeholder="Search By Email"
                    onChange={(e) => {
                      setfilterdata(
                        data.filter((item) => {
                          return item.userid.userEmail == e.target.value;
                        })
                      );
                    }}
                  ></input>
                </Col>
                <Col></Col>

                <Col></Col>
              </Row>

              <Table class="" responsive>
                <thead className="thead">
                  <tr>
                    <td>S.No</td>
                    <td>User Name</td>
                    <td>User Email</td>

                    <td>Status</td>

                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filterdata.length > 0
                    ? filterdata
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                        .map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                {item?.userid?.userName
                                  ? item?.userid?.userName
                                  : item?.userid?.userFullName}
                              </td>

                              <td>{item?.userid?.userEmail}</td>

                              {/* <td>
                                {item.userVerified ? "Verified" : "Unverify"}
                              </td> */}
                              <td>{item.status}</td>

                              <td>
                                <div className="action">
                                  {item.status == "pending" ? (
                                    <>
                                      <Button variant="primary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"approved")
                                      }}>
                                        Approved
                                      </Button>
                                      <Button variant="secondary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"rejected")
                                      }}>
                                        Rejected
                                      </Button>
                                    </>
                                  ) : item.status == "approved" ? (
                                    <Button variant="secondary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"rejected")
                                      }}>
                                        Rejected
                                      </Button>
                                  ) : (
                                    <Button variant="primary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"approved")
                                      }}>
                                        Approved
                                      </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    : data
                        ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                        .map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>

                              <td>
                                {item?.userid?.userName
                                  ? item?.userid?.userName
                                  : item?.userid?.userFullName}
                              </td>

                              <td>{item?.userid?.userEmail}</td>

                              {/* <td>
                                {item.userVerified ? "Verified" : "Unverify"}
                              </td> */}
                              <td>{item.status}</td>

                              <td>
                              <div className="action">
                                  {item.status == "pending" ? (
                                    <>
                                      <Button variant="primary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"approved")
                                      }}>
                                        Approved
                                      </Button>
                                      <Button variant="secondary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"rejected")
                                      }}>
                                        Rejected
                                      </Button>
                                    </>
                                  ) : item.status == "approved" ? (
                                    <Button variant="secondary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"rejected")
                                      }}>
                                        Rejected
                                      </Button>
                                  ) : (
                                    <Button variant="primary" onClick={(e)=>{
                                        
                                        updateStatus(item._id,"approved")
                                      }}>
                                        Approved
                                      </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                </tbody>
              </Table>
              <div className="menupagination">
                Showing{" "}
                {filterdata.length > 0
                  ? filterdata?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                      .length
                  : data?.slice((page - 1) * 10, (page - 1) * 10 + 10).length}
                - {datacount} results
                <div className="pagination">
                  {page == 1 ? (
                    <button disable style={{ cursor: "default" }}>
                      Prev
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page - 1);
                      }}
                    >
                      Prev
                    </button>
                  )}

                  {count?.map((item, index) => {
                    return (
                      <button
                        className={item == page ? "active" : ""}
                        onClick={() => {
                          setpage(item);
                        }}
                      >
                        {item}
                      </button>
                    );
                  })}
                  {page == count?.length ? (
                    <button disable style={{ cursor: "default" }}>
                      Next1
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setpage(page + 1);
                      }}
                    >
                      Next2
                    </button>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
}
