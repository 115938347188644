import { Action } from "../config/action";

// const OrderCollectionRef = collection(db, "Orders");
export const adminLogin = async (payload) => {
  const data = await Action.post("/login", payload);

  return data.data;
};

export const getreview = async () => {
  const data = await Action.get(`/getreview`);

  return data.data;
};
export const getreviewById = async (id) => {
  const data = await Action.get(`/getreviewbyid/${id}`);
  return data.data;
};

export const addreview = async (payload) => {
  const data = await Action.post("/createreview", payload);
  return data.data;
};
export const updatereview = async (id, payload) => {
  const data = Object.fromEntries(
    Object.entries(payload).filter(([_, v]) => (v != null) & (v != ""))
  );
// data has userreview attribute
  const updateddata = await Action.put(`/updatereviewbyid/${id}`, data);
  return updateddata.data;
};

export const deletereview = async (id) => {
  
// data has userreview attribute
  const updateddata = await Action.delete(`/deletereview/${id}`);
  return updateddata.data;
};
